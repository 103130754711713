/** @mui/material imports */
import Box from '@mui/material/Box';
/** @mui/icons-material imports */
/** React imports */
import React, { Context, createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
/** Goldspot imports */
import {
  AlertObject,
  ProjectObject,
  SignedInUser,
} from './TypeScript/interfaces';
import { getProject } from './utils/authenticated-requests/projects';
import { errorHandler } from './utils/error/error-handler';
import AppScopeLoadingComponent from './utils/components/AppScopeLoading';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';

const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppProps {
  user: SignedInUser;
  signOut: () => void;
}

export interface AppContextType {
  user: SignedInUser;
  appAlerts: AlertObject[] | [];
  setAppAlerts: (value: AlertObject[] | []) => void;
  addAppAlert: (value: AlertObject) => void;
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;
  activeProject: ProjectObject;
  setActiveProject: (value: ProjectObject | {}) => void;
  refreshProjectObject: () => Promise<void>;
  company: string;
  setCompany: (value: string | '') => void;
}

export const appContext: Context<AppContextType> =
  createContext<AppContextType>({
    user: {} as SignedInUser,
    appAlerts: [] as AlertObject[] | [],
    setAppAlerts: (value: any): void => {},
    addAppAlert: (value: AlertObject): void => {},
    appLoading: false,
    setAppLoading: (value: boolean): void => {},
    // The project is selected via URL
    activeProject: {},
    setActiveProject: (value: ProjectObject): void => {},
    refreshProjectObject: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    company: '', // for switch companies
    setCompany: (value: string): void => {},
  });

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [activeProject, setActiveProject] = useState<ProjectObject>({});
  const [appAlerts, setAppAlerts] = useState<AlertObject[] | []>([]);
  // High level state to control the Side Drawer via sibbling Navbar
  const [openedMobileDrawer, setOpenedMobileDrawer] = useState(false);
  const [company, setCompany] = useState<string>(
    user.signInUserSession.idToken.payload['custom:company']
  ); //default company

  // Create a query client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error, { meta }) => {
        errorHandler(error as Error);
        // Show error message
        if (meta?.errorMessage) {
          console.error(meta?.errorMessage);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, _variables, _context, mutation) => {
        errorHandler(error as Error);
        // Show error message
        if (mutation.meta?.errorMessage) {
          console.error(mutation.meta?.errorMessage);
        }
      },
    }),
  });

  const addAppAlert = (alert: AlertObject): void => {
    setAppAlerts([...appAlerts, alert]);
  };

  const handleCloseAlert = (): void => {
    appAlerts.shift(); // Removes the first Alert (closing one Aler at a time)
    setAppAlerts([...appAlerts]);
  };

  /**
   * Get project object and update context
   */
  const refreshProjectObject = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (activeProject?.id) {
        getProject(company, activeProject.id)
          .then((value: ProjectObject) => {
            setActiveProject(value);
            resolve();
          })
          .catch((err) => {
            errorHandler(err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <appContext.Provider
          value={{
            user,
            appAlerts,
            setAppAlerts,
            addAppAlert,
            appLoading,
            setAppLoading,
            activeProject,
            setActiveProject,
            refreshProjectObject,
            company,
            setCompany,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <React.Suspense fallback={<AppScopeLoadingComponent />}>
              <AppRouter
                user={user}
                setOpenedMobileDrawer={setOpenedMobileDrawer}
                openedMobileDrawer={openedMobileDrawer}
                signOut={signOut}
              />
            </React.Suspense>
          </Box>
          <Backdrop sx={{ color: '#fff', zIndex: '2000' }} open={appLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {appAlerts.length > 0 && appAlerts[0].severity === 'error' ? (
            <Snackbar open={true}>
              <Alert
                severity={appAlerts[0].severity}
                onClose={handleCloseAlert}
              >
                {appAlerts[0].message}
              </Alert>
            </Snackbar>
          ) : (
            appAlerts.length > 0 && (
              <Snackbar
                open={true}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
              >
                <Alert
                  severity={appAlerts[0].severity}
                  onClose={handleCloseAlert}
                >
                  {appAlerts[0].message}
                </Alert>
              </Snackbar>
            )
          )}
        </appContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
